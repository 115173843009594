jQuery(function($) {

/*homepage slider*/
	$(".owl-carousel.slajdi").owlCarousel({
		center: true,
    items:1,
    loop:true,
    autoWidth: true,
    margin: 66,
    responsive:{
    		0: {
    			margin: 20
    		},
        768:{
            margin: 60
        }
    }
	});

	/*enablamo navigacijo med slajdi na klik, ne samo na drag*/
	$('.owl-carousel.slajdi').on('click', '.owl-item', function(e) {
	  var carousel = $('.owl-carousel.slajdi').data('owl.carousel.slajdi');
	  e.preventDefault();
	  carousel.to(carousel.relative($(this).index()));
	});


/* navigation */
	$('#nav-icon').click(function(){
		$(this).toggleClass('open');
		if ($(this).hasClass('open')){
			$("nav.nav-primary.nav-header").fadeIn("fast");
		}
		else {
			$("nav.nav-primary.nav-header").fadeOut("fast");
		}
	});
	
	/*init*/
	$("#menu-item-293").addClass("arrow_down");

	$('.nav-header .revme-menu-dropdown').hover(function() {
	  $(this).find('ul').stop(true, true).delay(50).fadeIn("fast");
	  $("#menu-item-293").removeClass("arrow_down");
	  $("#menu-item-293").addClass("arrow_up");
}, function() {
	  $(this).find('ul').stop(true, true).delay(50).fadeOut("fast");
	  $("#menu-item-293").removeClass("arrow_up");
	  $("#menu-item-293").addClass("arrow_down");
});


	$(".nav-header .revme-menu-dropdown ul li a").click(function(e){
		e.stopPropagation();
	});
/* // navigation // */

/*form coloring*/
	$('.wpcf7').on('wpcf7:invalid', function (e) {
		$(".zgodbe-form .wpcf7-form-control-wrap.your-email input").css("border", "1px solid #e32f29");
	});

	$('.wpcf7').on('wpcf7:mailsent', function (e) {
		$(".zgodbe-form .wpcf7-form-control-wrap.your-email input").css("border", "1px solid #1AB24E");
		$(".zgodbe-form .wpcf7-form-control-wrap.your-email input").attr("placeholder", "Uspešno ste se naročili na novice.");
		$(".zgodbe-form .wpcf7-form-control.wpcf7-submit").addClass("green-button");
		$(".zgodbe-form .wpcf7-form-control.wpcf7-submit").val("Prijava uspešna");
	});


	$(".zgodbe-form .wpcf7-form-control-wrap.your-email input").focus(function(){
		$(".zgodbe-form .wpcf7-form-control.wpcf7-submit").val("NAROČI SE");
		$(".zgodbe-form .wpcf7-form-control.wpcf7-submit").removeClass("green-button");
		$(this).css("border", "1px solid #ffbe58");	
	});
/* // form coloring // */



	$("a.link-show-more").click(function(e){
		e.preventDefault();
		($(this).text() === "Prikaži odgovor +") ? $(this).text("Skrij odgovor -") : $(this).text("Prikaži odgovor +");
		if ($(this).text() === "Skrij odgovor -") {
			$(this).next().css("padding-top", "15px");
			$(this).parent().parent().css("box-shadow", "0 12px 42px 12px rgba(0, 0, 0, 0.04)");
		}
		else {
			$(this).parent().parent().css("box-shadow", "none");
		}
		$(this).next().slideToggle("fast");
	});



	$(function() {
	   $.fn.almDone = function(){
	   		$(".alm-load-more-btn.more.button.loadmore_button.done").hide();
	   };
	});



		size_li = $(".nasveti-card").size();

		if ($(".nasveti-card").hasClass("six")) {
			x = 6;
		}
		else {
			x = 3;
		}
    
    $('.nasveti-card:lt('+x+')').show().css("display", "flex");


    var st_prikazanih = $('.nasveti-card').filter(function() {
			return $(this).css('display') !== 'none';
		}).length;

		if (st_prikazanih === size_li) {
      $('#loadMore').remove();
    }


    
    $('#loadMore').click(function (e) {
    	e.preventDefault();
      x = (x+3 <= size_li) ? x+3 : size_li;
      $('.nasveti-card:lt('+x+')').fadeIn("fast").css("display", "flex");

      var st_prikazanih = $('.nasveti-card').filter(function() {
				return $(this).css('display') !== 'none';
			}).length;

      if (st_prikazanih === size_li) {
      	$(this).remove();
    	} 
    });


    
	
});





















/*homepage slider*/
	jQuery(".owl-carousel.vprasalnik").owlCarousel({
		center: true,
    items:1,
    nav: false,
    dots: true,
    touchDrag  : false,
   	mouseDrag  : false
    
	});













(function ($, root, undefined) {

if ($("body").hasClass("tveganje-za-psoriaticni-artritis")) {

	$(function () {




console.log("asdasd");

    // ############################################################
		// ############################################################
		//		questionnaire
		// ############################################################
		// ############################################################

		var questionnaire = (function() {

			var $questions = $('.vprasanje-wrap'),
				$countQuestions = $('.vprasanje-wrap').length,
				$answerElements = $('input[type=radio]'),
				$answerPoints = $('input[type=text]'),
				$finishQuestionnaireBtn = $('.finish-btn'),
				$questi = $('.vprasanje-tekst'),
				$emptydiv = $('.vprasalnik-finish'),
				$questionDiv = $('.questionnaire'),
				$printpdf = $('.dlpdf'),
				questionIndex = 0,
				quest = [],
				answers = [],
				answerPoints = [],
				dispobj = [],
				obisk = false,
				vnetna = false,
				affectedText;

			/* POKAŽE NASLEDNJE VPRAŠANJE */

			var displayNextQuestion = function() {

				$(".owl-carousel.vprasalnik").trigger('next.owl.carousel');

				var newIndex = questionIndex + 1;
				//$($questions[questionIndex]).removeClass("active");
				//$($questions[newIndex]).addClass("active");

				gtag('event', 'vprašanje psoriatični artritis', {
				  'event_category': "vprašalnik",
				  'event_label': newIndex
				});

				quest.push($($questi)[questionIndex].innerHTML);
				if($($questions[questionIndex]).hasClass("dropdown")) {
					answers.push($('#q-' + newIndex + '').val());
				}
				else {
					answers.push($('label[for="' + this.id + '"]').text());
					answerPoints.push($('#' + this.id + '-val').val());
				}
				questionIndex++;
				if(questionIndex == $countQuestions) {
					gtag('event', 'psoriatični artritis', {
					  'event_category': "vprašalnik",
					  'event_label': "končan vprašalnik"
					});
					$finishQuestionnaireBtn.removeClass("hidden-xs-up");
				}
				//var offset = $($questions[newIndex]).offset();
				//offset.top -= 40;
				//$('html, body').animate({scrollTop : offset.top},500);
				
			};











// Pokaže rezultate

			var clickFinishButton = function() {

				$(".dlpdf-wrap").removeClass("d-none");

				//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Končan vprašalnik');

				//$($questionDiv[1]).removeClass("hidden");
				//$($questionDiv[0]).addClass("hidden");

				var countBack = 0;
				var countAll = 0;

				$(".finish-btn-wrap").addClass("hidden-xs-up");

				if(answerPoints[0] == 1 || answerPoints[1] == 1) {
					for (var i = 6; i <= 10; i++) {
						if (answerPoints[i] == 1) {
							countBack++;
						}
					}
					if (countBack >= 4) {
						countAll++;
						vnetna = true;
					}
					for (var a = 2; a <=5; a++) {
						if (answerPoints[a] == 1) {
							countAll++;
						}
					}
					if (countAll >= 1) {
						obisk = true;
					}
				}


				for(var u = 0; u <= 5; u++) {
					$('.rezultati-vprasanja').append('<div class="rezultati-vprasanja-wrap"><div class="rezultati-quest">' + quest[u] + '</div><div class="rezultati-answer">' + answers[u] + '</div></div>');
					dispobj.push({
						question: quest[u],
						answer: answers[u]
					});
				}
				if(countBack >= 4) {
					$('.rezultati-vprasanja').append('<div class="rezultati-vprasanja-wrap"><div class="rezultati-quest">Vnetna bolečina v križu</div><div class="rezultati-answer">Da</div></div>');
					dispobj.push({
						question: "Vnetna bolečina v križu",
						answer: "Da"
					});
				}
				else {
					$('.rezultati-vprasanja').append('<div class="rezultati-vprasanja-wrap"><div class="rezultati-quest">Vnetna bolečina v križu</div><div class="rezultati-answer">Ne</div></div>');
					dispobj.push({
						question: "Vnetna bolečina v križu",
						answer: "Ne"
					});
				}
				if(obisk === true)
				{
					$('.vprasalnik-glava.two').append('<div class="vprasalnik-glava vprasanje-tekst">Tveganje obstaja.</div><div class="vprasalnik-glava"><p>Vaši odgovori kažejo sum na psoriatični artritis, zato vam priporočamo obisk pri osebnem zdravniku, ki vas bo napotil k specialistu revmatologu.</p></div>');
					affectedText = "Vaši odgovori kažejo sum na psoriatični artritis, zato vam priporočamo obisk pri osebnem zdravniku, ki vas bo napotil k specialistu revmatologu.";
					
					gtag('event', 'psoriatični artritis', {
					  'event_category': "vprašalnik",
					  'event_label': "priporočen obisk revmatologa"
					});
					//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Priporočen obisk pri revmatologu');

				}
				else {
					$('.vprasalnik-glava.two').append('<div class="vprasalnik-glava vprasanje-tekst">Ni tveganja</div><div class="vprasalnik-glava"><p>Vaši odgovori ne kažejo suma na psoriatični artritis, vendar priporočamo, da ste pozorni na sklepe. Če se pojavijo bolečine oziroma spremembe v sklepih ali drugi zgoraj navedeni simptomi, to povejte osebnemu zdravniku, ki bo ustrezno ukrepal oziroma vas napotil k specialistu revmatologu.</p></div>');
					affectedText = "Vaši odgovori ne kažejo suma na psoriatični artritis, vendar priporočamo, da ste pozorni na sklepe. Če se pojavijo bolečine oziroma spremembe v sklepih ali drugi zgoraj navedeni simptomi, to povejte osebnemu zdravniku, ki bo ustrezno ukrepal oziroma vas napotil k specialistu revmatologu.";
					
					gtag('event', 'psoriatični artritis', {
					  'event_category': "vprašalnik",
					  'event_label': "obisk revmatologa ni potreben"
					});
					//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Obisk pri revmatologu ni potreben');
				}
			};







			// Pokaže rezultate
/*
			var clickFinishButton = function() {
				//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Končan vprašalnik');

				//$($questionDiv[1]).removeClass("hidden-xs-up");
				//$($questionDiv[0]).addClass("hidden-xs-up");


				
				

				$(".finish-btn-wrap").addClass("hidden-xs-up");



				var countBack = 0;
				var countAll = 0;

				


				if(answerPoints[0] == 1 || answerPoints[1] == 1) {
					for (var a = 2; a <=5; a++) {
						if (answerPoints[a] == 1) {
							countAll++;
						}
					}
					if (countAll >= 1) {
						obisk = true;
					}
				}


				for(var u = 0; u < $countQuestions; u++) {
					$('.rezultati-vprasanja').append('<div class="rezultati-vprasanja-wrap"><div class="rezultati-quest">' + quest[u] + '</div><div class="rezultati-answer">' + answers[u] + '</div></div>');
					dispobj.push({
						question: quest[u],
						answer: answers[u]
					});
				}

				if(obisk === true)
				{
					$('.vprasalnik-glava.two').append('<div class="vprasalnik-glava vprasanje-tekst">Tveganje obstaja.</div><div class="vprasalnik-glava"><p>Vaši odgovori kažejo sum na psoriatični artritis, zato vam priporočamo obisk pri osebnem zdravniku, ki vas bo napotil k specialistu revmatologu.</p></div>');
					affectedText = "Vaši odgovori kažejo sum na psoriatični artritis, zato vam priporočamo obisk pri osebnem zdravniku, ki vas bo napotil k specialistu revmatologu.";
					//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Priporočen obisk pri revmatologu');
				}
				else {
					$('.vprasalnik-glava.two').append('<div class="vprasalnik-glava vprasanje-tekst">Ni tveganja</div><div class="vprasalnik-glava"><p>Vaši odgovori ne kažejo suma na psoriatični artritis, vendar priporočamo, da ste pozorni na sklepe. Če se pojavijo bolečine oziroma spremembe v sklepih ali drugi zgoraj navedeni simptomi, to povejte osebnemu zdravniku, ki bo ustrezno ukrepal oziroma vas napotil k specialistu revmatologu.</p></div>');
					affectedText = "Vaši odgovori ne kažejo suma na psoriatični artritis, vendar priporočamo, da ste pozorni na sklepe. Če se pojavijo bolečine oziroma spremembe v sklepih ali drugi zgoraj navedeni simptomi, to povejte osebnemu zdravniku, ki bo ustrezno ukrepal oziroma vas napotil k specialistu revmatologu.";
					//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Obisk pri revmatologu ni potreben');
				}
				//$('html, body').animate({scrollTop : 0},800);

			};
*/
			var createFinishPDF = function() {

				//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Prenešen PDF');

				var today = new Date();
				var dd = today.getDate();
				var mm = today.getMonth()+1; //January is 0!
				var yyyy = today.getFullYear();

				if(dd<10) {
				    dd='0'+dd;
				}

				if(mm<10) {
				    mm='0'+mm;
				}

				today = dd+'. '+mm+'. '+yyyy;

				var externalDataRetrievedFromServer = dispobj;

				// Zgradi tabelo za PDF

				function buildTableBody(data, columns) {
				    var body = [];

				    data.forEach(function(row) {
				        var dataRow = [];

				        columns.forEach(function(column) {
				        		console.log(row[column]);
				            dataRow.push(row[column].toString());
				        });

				        body.push(dataRow);
				    });

				    return body;
				}

				function table(data, columns) {
				    return {
								fontSize: 10,
				        table: {
				            body: buildTableBody(data, columns)
				        }
				    };
				}

				var dad = {

					header: {
					    columns: [
					      { text: '' + today, alignment: 'right', fontSize: 8, margin: [ 0, 10, 10, 0], color: '#AAAAAA' }
					    ]
					  },

					footer: {
					    columns: [
					      { text: 'Vprašalnik je bil izpolnjen na spletni strani revma.si', alignment: 'center', fontSize: 8, margin: [ 20, 0, 0, 0], color: '#AAAAAA' }
					    ]
					  },

				    content: [
				    	{ text: 'Rezultat vprašalnika o tveganju za psoriatični artritis', style: 'header', margin: [ 0, 0, 0, 10], fontSize: 12, alignment: 'center', color: '#979797' },
					    { text: 'Datum izpolnjevanja: ' + today, margin: [ 0, 0, 0, 10], fontSize: 10 },
					    // { text: 'Glede na obolelost po telesu, luskavica obsega približno ' + $percentageaffected + '% telesa. Obolele predele si lahko ogledate na sliki:', margin: [ 0, 0, 0, 10] },
					    /*{ text: 'Označili ste naslednje prizadete predele kože.', margin: [ 0, 0, 0, 10], fontSize: 10 },
					    {
					    	image: front_dataURL,
					    	alignment: 'center',
					    	width: 300
					    },*/
				        { text: 'Vprašanja:', style: 'header', margin: [ 0, 5, 0, 10], fontSize: 11 },
				        table(externalDataRetrievedFromServer, ['question', 'answer']),
				        { text: 'Rezultat rešenega vprašalnika: ' + affectedText, margin: [ 0, 5, 0, 10], fontSize: 10 }
				    ]
				};
				gtag('event', 'psoriatični artritis', {
				  'event_category': "vprašalnik",
				  'event_label': "prenos pdf-ja"
				});

				var filename = 'Rezultati vprasalnika Psoriaticni artritis';
				filename = filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = filename + '.pdf';

				pdfMake.createPdf(dad).download(filename);

			};

			$answerElements.on('click', displayNextQuestion);
			$finishQuestionnaireBtn.on('click', clickFinishButton);
			$printpdf.on('click', createFinishPDF);

		})();

	});

}
})(jQuery, this);
























































(function ($, root, undefined) {


if ($("body").hasClass("vnetna-bolecina-v-krizu")) {


	$(function () {



    // ############################################################
		// ############################################################
		//		questionnaire
		// ############################################################
		// ############################################################

		var questionnaire = (function() {

			var $questions = $('.vprasanje-wrap'),
				$countQuestions = $('.vprasanje-wrap').length,
				$answerElements = $('input[type=radio]'),
				$answerPoints = $('input[type=text]'),
				$finishQuestionnaireBtn = $('.finish-btn'),
				$questi = $('.vprasanje-tekst'),
				$emptydiv = $('.vprasalnik-finish'),
				$questionDiv = $('.questionnaire'),
				$printpdf = $('.dlpdf'),
				questionIndex = 0,
				quest = [],
				answers = [],
				answerPoints = [],
				dispobj = [],
				obisk = false,
				vnetna = false,
				affectedText;

			/* POKAŽE NASLEDNJE VPRAŠANJE */

			var displayNextQuestion = function() {

				$(".owl-carousel.vprasalnik").trigger('next.owl.carousel');

				

				var newIndex = questionIndex + 1;

				gtag('event', 'vprašanje bolečina v križu', {
				  'event_category': "vprašalnik",
				  'event_label': newIndex
				});
				//$($questions[questionIndex]).removeClass("active");
				//$($questions[newIndex]).addClass("active");

				quest.push($($questi)[questionIndex].innerHTML);
				if($($questions[questionIndex]).hasClass("dropdown")) {
					answers.push($('#q-' + newIndex + '').val());
				}
				else {
					answers.push($('label[for="' + this.id + '"]').text());
					answerPoints.push($('#' + this.id + '-val').val());
				}
				questionIndex++;
				if(questionIndex == $countQuestions) {


				gtag('event', 'bolečina v križu', {
				  'event_category': "vprašalnik",
				  'event_label': "končan vprašalnik"
				});

				$finishQuestionnaireBtn.removeClass("hidden-xs-up");

				}
				//var offset = $($questions[newIndex]).offset();
				//offset.top -= 40;
				//$('html, body').animate({scrollTop : offset.top},500);
				
			};


			// Pokaže rezultate

			var clickFinishButton = function() {
				//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Končan vprašalnik');

				//$($questionDiv[1]).removeClass("hidden-xs-up");
				//$($questionDiv[0]).addClass("hidden-xs-up");

				//console.log("quests[]:");
				//console.log(quest);
				$(".dlpdf-wrap").removeClass("d-none");
				
				var countBack = 0;
				var countAll = 0;

				$(".finish-btn-wrap").addClass("hidden-xs-up");
				//console.log(answerPoints);

				for (var i = 0; i < answerPoints.length; i++) {
					if (answerPoints[i] == 1) {
						countBack++;
					}
				}

				if (countBack >= 4) {
					vnetna = true;
				}

				for(var u = 0; u < $countQuestions; u++) {
					$('.rezultati-vprasanja').append('<div class="rezultati-vprasanja-wrap"><div class="rezultati-quest">' + quest[u] + '</div><div class="rezultati-answer">' + answers[u] + '</div></div>');
					dispobj.push({
						question: quest[u],
						answer: answers[u]
					});
				}

				if(countBack >= 4) {
					//$('.rezultati-vprasanja').append('<div class="rezultati-vprasanja-wrap"><div class="rezultati-quest">Vnetna bolečina v križu</div><div class="rezultati-answer">Da</div></div>');
					$('.vprasalnik-glava.two').append('<div class="vprasalnik-glava vprasanje-tekst">Sum obstaja</div><div class="vprasalnik-glava"><p>Vaši odgovori kažejo sum na vnetno bolečino v križu, zato vam priporočamo obisk pri osebnem zdravniku, ki vas bo napotil k specialistu.</p></div>');
					affectedText = "Vaši odgovori kažejo sum na vnetno bolečino v križu, zato vam priporočamo obisk pri osebnem zdravniku, ki vas bo napotil k specialistu.";
					dispobj.push({
						question: "Vnetna bolečina v križu",
						answer: "Da"
					});

					gtag('event', 'bolečina v križu', {
					  'event_category': "vprašalnik",
					  'event_label': "sum obstaja"
					});
				}
				else {
					//$('.rezultati-vprasanja').append('<div class="rezultati-vprasanja-wrap"><div class="rezultati-quest">Vnetna bolečina v križu</div><div class="rezultati-answer">Ne</div></div>');
					$('.vprasalnik-glava.two').append('<div class="vprasalnik-glava vprasanje-tekst">Ni tveganja</div><div class="vprasalnik-glava"><p>Vaši odgovori ne kažejo suma na vnetno bolečino v križu.</p></div>');
					affectedText = "Vaši odgovori ne kažejo suma na vnetno bolečino v križu.";
					dispobj.push({
						question: "Vnetna bolečina v križu",
						answer: "Ne"
					});

					gtag('event', 'bolečina v križu', {
					  'event_category': "vprašalnik",
					  'event_label': "ni tveganja"
					});

				}
			};

			var createFinishPDF = function() {

				//ga('send', 'event', 'Vprašalnik', 'Vprašalnik revmatolog', 'Prenešen PDF');

				var today = new Date();
				var dd = today.getDate();
				var mm = today.getMonth()+1; //January is 0!
				var yyyy = today.getFullYear();

				if(dd<10) {
				    dd='0'+dd;
				}

				if(mm<10) {
				    mm='0'+mm;
				}

				today = dd+'. '+mm+'. '+yyyy;

				var externalDataRetrievedFromServer = dispobj;

				// Zgradi tabelo za PDF

				function buildTableBody(data, columns) {
				    var body = [];

				    data.forEach(function(row) {
				        var dataRow = [];

				        columns.forEach(function(column) {
				            dataRow.push(row[column].toString());
				            console.log(row[column]);
				        });

				        body.push(dataRow);
				    });

				    return body;
				}

				function table(data, columns) {
				    return {
								fontSize: 10,
				        table: {
				            body: buildTableBody(data, columns)
				        }
				    };
				}

				var dad = {

					header: {
					    columns: [
					      { text: '' + today, alignment: 'right', fontSize: 8, margin: [ 0, 10, 10, 0], color: '#AAAAAA' }
					    ]
					  },

					footer: {
					    columns: [
					      { text: 'Vprašalnik je bil izpolnjen na spletni strani revma.si', alignment: 'center', fontSize: 8, margin: [ 20, 0, 0, 0], color: '#AAAAAA' }
					    ]
					  },

				    content: [
				    	{ text: 'Rezultat vprašalnika o vnetni bolečini v križu', style: 'header', margin: [ 0, 0, 0, 10], fontSize: 12, alignment: 'center', color: '#979797' },
					    { text: 'Datum izpolnjevanja: ' + today, margin: [ 0, 0, 0, 10], fontSize: 10 },
					    // { text: 'Glede na obolelost po telesu, luskavica obsega približno ' + $percentageaffected + '% telesa. Obolele predele si lahko ogledate na sliki:', margin: [ 0, 0, 0, 10] },
					    /*{ text: 'Označili ste naslednje prizadete predele kože.', margin: [ 0, 0, 0, 10], fontSize: 10 },
					    {
					    	image: front_dataURL,
					    	alignment: 'center',
					    	width: 300
					    },*/
				        { text: 'Vprašanja:', style: 'header', margin: [ 0, 5, 0, 10], fontSize: 11 },
				        table(externalDataRetrievedFromServer, ['question', 'answer']),
				        { text: 'Rezultat rešenega vprašalnika: ' + affectedText, margin: [ 0, 5, 0, 10], fontSize: 10 }
				    ]
				};

				gtag('event', 'bolečina v križu', {
				  'event_category': "vprašalnik",
				  'event_label': "prenos pdf-ja"
				});
				var filename = 'Rezultati vprasalnika Vnetna bolecina v krizu';
				filename = filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = filename + '.pdf';
				
				pdfMake.createPdf(dad).download(filename);


			};

			$answerElements.on('click', displayNextQuestion);
			$finishQuestionnaireBtn.on('click', clickFinishButton);
			$printpdf.on('click', createFinishPDF);

		})();

	});

}

})(jQuery, this);

